import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FullProject from "../components/gallery/fullProject"
import FeaturedTestimonial from "../components/testimonials/featuredTestimonial"
import BannerActions from "../components/banners/bannerActions"

import Banner from "../images/banner.png"

export default function Projects() {
  return (
    <Layout>
      <SEO title="Electrical Project Gallery" />
      <BannerActions img={Banner} />
      <FullProject />
      <FeaturedTestimonial
        testimonial="We use Royco for any and all of our electrical issues with our commercial and residential units. Russ and James are fast, accommodatable, professional, friendly and very honest."
        company="Henderson Property Management"
        name="DANIELLE BLACKIE"
      />
    </Layout>
  )
}
