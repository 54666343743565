import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Section, Container, GridTwo } from "../layoutComponents"
import Title from "../title"

export default function FullProject() {
  return (
    <Section>
      <Container className="spacing">
        <Title title="royco electric full project gallery" />
        <GridTwo>
          <StaticImage
            src="../../images/projects/residential/residential-rough-in.jpg"
            alt="residential rough in"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/residential/whole-home-standby-generators.jpg"
            alt="whole home standby generators"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/residential/roughing-in-custom-home.jpg"
            alt="roughing in a custom home"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/commercial/single-phase-commercial-load-center.jpg"
            alt="single phase commercial load center"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/residential/100a-apartment-load-centre.jpg"
            alt="100a apartment load centre"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/commercial/events-container-panel.jpg"
            alt="events container panel"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/industrial/30-kva-transformer-distribution.jpg"
            alt="30kva transformer distribution"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/residential/2-200a-residential-distribution.jpg"
            alt="200a residential distribution"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/commercial/emt-conduit-installation-and-wire-pulling-full-production-workshop.jpg"
            alt="emt conduit installaiton and wire pulling"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/industrial/industrial-troubleshooting-of-lighting-wiring-systems.jpg"
            alt="industrial troubleshooting of lighting wiring systems"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/residential/200a-through-roof.jpg"
            alt="200a through roof"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/commercial/storage-container-panel-work.jpg"
            alt="storage container panel work"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/industrial/transformer-for-cnc-machine.jpg"
            alt="transformer for cnc machine"
            width={500}
            height={500}
            objectFit="cover"
          />
          <StaticImage
            src="../../images/projects/residential/lake-muskoka-boathouse.jpg"
            alt="lake muskoka boathouse"
            width={500}
            height={500}
            objectFit="cover"
          />
        </GridTwo>
      </Container>
    </Section>
  )
}
